<template>
  <v-row dense>
    <v-col cols="12" class="pl-0">
      <div v-html="sanitizeHtml(placeRequirement)" />
    </v-col>
    <v-col cols="12" class="my-6 pl-0">
      <CdeCard
        rounded="lg"
        min-height="50px"
        class="align-content-center text-white bg-secondary px-3">
        <v-card-text>
          <p class="font-weight-bold">{{ t('please_be_advised') }}</p>
          <p>
            <span class="font-weight-bold">{{ t('fourteen_days_duration_free') }}</span>
            {{ t('is_included_however') }}
            <span class="font-weight-bold">{{ t('every_week_price') }}</span>
            {{ t('rent_fee_charge') }}
          </p>
        </v-card-text>
      </CdeCard>
    </v-col>
  </v-row>
</template>
<script setup lang="ts">
import { sanitizeHtml } from '~/util/sanitizer'
const { t } = useI18n()
defineProps<{
  placeRequirement: string | null
}>()
</script>
